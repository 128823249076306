<template>
  <b-modal
    id="userServiceModal"
    :title="`Add Services to ${userForService.first_name} ${userForService.last_name} (${userForService.username})`"
    ok-title="Add"
    :ok-disabled="isProcessing"
    :cancel-disabled="isProcessing"
    no-close-on-backdrop
    @ok="addUserService"
    @shown="getServices"
  >
    <b-overlay :show="isProcessing">
      <b-form-group label="Services">
        <v-select
          v-model="userServices"
          :reduce="service => service.value"
          placeholder="Select a service"
          :options="serviceOptions"
          :create-option="value => ({ label: value, value })"
          multiple
          taggable
        />
      </b-form-group>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BFormGroup,
    BOverlay,
    vSelect,
  },
  props: {
    userForService: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isProcessing: false,
      services: [],
      userServices: [],
    }
  },
  computed: {
    serviceOptions() {
      return this.services
        .map(service => ({
          label: service.name,
          value: service.uuid,
        }))
    },
  },
  watch: {
    userForService: {
      handler(value) {
        this.userServices = value?.services?.map(service => service.uuid)
      },
      immediate: true,
    },
  },
  methods: {
    getServices() {
      this.isProcessing = true
      useJwt.getServices()
        .then(response => {
          this.services = response.data
          this.isProcessing = false
        })
    },
    addUserService(event) {
      event.preventDefault()
      this.isProcessing = true
      useJwt.addUserService({
        userId: this.userForService.id,
        services: this.userServices,
      }).then(response => {
        this.showSuccessMessage(response)
        this.isProcessing = false
        this.$emit('refetch')
        this.$bvModal.hide('userServiceModal')
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}
</script>

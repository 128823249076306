<template>
  <div>
    <b-row>
      <b-col
        md="12"
      >
        <b-card>
          <b-overlay
            :show="isProcessing"
          >
            <b-button
              v-b-tooltip.hover="`Add User`"
              variant="primary"
              class="mb-1 float-right"
              @click="userFormModel"
            >
              <b-spinner
                v-show="isProcessing"
                small
                class="mr-1"
              />
              <feather-icon icon="UserPlusIcon" /> Add User
            </b-button>
            <b-table-simple
              striped
              hover
              responsive
            >
              <b-thead>
                <b-tr>
                  <b-td>Name</b-td>
                  <b-td>Username</b-td>
                  <b-td>Email</b-td>
                  <b-td>Services</b-td>
                  <b-td>Actions</b-td>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="user, index in userList"
                  :key="index"
                >
                  <b-td>{{ user.first_name }} {{ user.last_name }}</b-td>
                  <b-td>
                    {{ user.username }}
                    <sup
                      v-if="user.is_superuser"
                    >
                      <b-badge
                        variant="light-success"
                      >
                        Admin
                      </b-badge>
                    </sup>
                  </b-td>
                  <b-td>{{ user.email }}</b-td>
                  <b-td>
                    <span
                      v-for="service, sindex in user.services"
                      :key="sindex"
                    >
                      <b-badge
                        v-if="sindex < 2"
                        variant="light-primary"
                        class="cursor-pointer mr-1"
                        :style="`padding:10px;font-size:14px;`"
                      >
                        {{ service.name }}
                      </b-badge>
                    </span>
                    <b-badge
                      v-if="user?.services?.length > 2"
                      v-b-tooltip.hover="`View All ${user?.services?.length} Services`"
                      variant="light-primary"
                      class="cursor-pointer mr-1"
                      :style="`padding:10px;font-size:14px;`"
                      @click="showUserAllServices(user)"
                    >
                      View All...
                    </b-badge>
                  </b-td>
                  <b-td>
                    <b-button
                      v-b-tooltip.hover="`Edit`"
                      variant="success"
                      class="mr-1"
                      @click="updateUserFormModel(user)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>

                    <b-button
                      v-if="!user?.is_superuser"
                      v-b-tooltip.hover="`Add Services`"
                      variant="primary"
                      @click="addUserService(user)"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      :visible="showModal"
      :title="modalTitle"
      ok-only
      no-close-on-backdrop
      @close="resetAndClose"
      @hide="resetAndClose"
    >
      <validation-observer ref="userValidation">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label for="firstname">First Name</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="first name"
                  rules="required"
                >
                  <b-form-input
                    id="firstname"
                    v-model="form.first_name"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    placeholder="First Name"
                    name="firstname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label for="lastname">Last Name</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="last name"
                  rules="required"
                >
                  <b-form-input
                    id="lastname"
                    v-model="form.last_name"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    placeholder="Last Name"
                    name="lastname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group>
            <label for="username">Username</label>
            <validation-provider
              v-slot="{ errors }"
              name="username"
              rules="required"
            >
              <b-form-input
                id="username"
                v-model="form.username"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                placeholder="Username"
                name="username"
                :disabled="isUpdating"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="userEmail"
                v-model="form.email"
                :state="errors.length > 0 ? false:null"
                name="userEmail"
                placeholder="john@example.com"
                :disabled="isUpdating"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- <b-form-group
            label="Services"
            label-for="service"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Services"
              rules="required"
            >
              <b-form-input
                id="services"
                v-model="form.services"
                :state="errors.length > 0 ? false:null"
                name="services"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->
          <b-form-group
            label="Password"
            label-for="password"
          >
            <validation-provider
              v-slot="{ errors }"
              name="password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="form.password"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer="{}">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              type="submit"
              variant="primary"
              block
              @click="validateForm"
            >
              <b-spinner
                v-show="isProcessing"
                small
                class="mr-1"
              />
              <feather-icon icon="UserPlusIcon" /> {{ modalTitle }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-modal
      id="user-services-modal"
      :title="`All Services of ${userServicesDetail.first_name} ${userServicesDetail.last_name} (${userServicesDetail.username})`"
      ok-only
      ok-title="Close"
      no-close-on-backdrop
      @close="resetUserServiceModal"
      @hide="resetUserServiceModal"
    >
      <b-badge
        v-for="userService, usindex in userServicesDetail.services"
        :key="usindex"
        variant="light-primary"
        class="cursor-pointer mr-1 mb-1"
        :style="`padding:10px;font-size:14px;`"
      >
        {{ userService.name }}
      </b-badge>
    </b-modal>
    <UserServicesModal
      :user-for-service="userForService"
      @refetch="getUsers"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BThead, BTbody, BTr, BTd, BCard, BTableSimple, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BModal, BSpinner, VBTooltip, BBadge, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import UserServicesModal from './components/UserServicesModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BModal,
    BSpinner,
    BBadge,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    UserServicesModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isProcessing: false,
      showModal: false,
      userList: [],
      form: {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
      },
      required,
      email,
      modalTitle: 'Add User',
      isUpdating: false,
      userForService: {},
      userServicesDetail: {},
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.isProcessing = true
      useJwt.getUsersList()
        .then(response => {
          this.userList = response.data
          this.isProcessing = false
        }).catch(error => {
          console.log(error)
        })
    },
    userFormModel() {
      this.modalTitle = 'Add User'
      this.showModal = true
      this.isUpdating = false
    },
    updateUserFormModel(user) {
      this.form = JSON.parse(JSON.stringify(user))
      this.showModal = true
      this.isUpdating = true
      this.modalTitle = 'Update User'
    },
    resetAndClose() {
      this.$refs.userValidation.reset()
      this.form.id = ''
      this.form.first_name = ''
      this.form.last_name = ''
      this.form.username = ''
      this.form.email = ''
      this.form.password = ''
      this.showModal = false
      this.isProcessing = false
    },
    resetUserServiceModal() {
      this.userServicesDetail = {}
      this.$bvModal.hide('user-services-modal')
    },
    async validateForm(event) {
      event.preventDefault()
      this.$refs.userValidation.validate().then(success => {
        if (success) {
          if (this.isUpdating) this.updateForm()
          else this.submitForm()
        }
      })
    },
    submitForm() {
      this.isProcessing = true
      useJwt.storeUser(this.form).then(response => {
        this.resetAndClose()
        this.showSuccessMessage(response)
        this.getUsers()
      })
    },
    updateForm() {
      this.isProcessing = true
      useJwt.updateUser(this.form.id, this.form).then(response => {
        this.resetAndClose()
        this.showSuccessMessage(response)
        this.getUsers()
      })
    },
    addUserService(user) {
      this.userForService = user
      this.$bvModal.show('userServiceModal')
    },
    showUserAllServices(user) {
      this.userServicesDetail = user
      this.$bvModal.show('user-services-modal')
    },
  },
}
</script>
